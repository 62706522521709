


@font-face {
  font-family: 'LibreFranklin_Bold';
  src: url('../fonts/LibreFranklin-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'LibreFranklin_Regular';
  src: url('../fonts/LibreFranklin-Regular.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'LibreFranklin_Thin';
  src: url('../fonts/LibreFranklin-Thin.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

html {
  font-size: 16px;
}

@media only screen and (max-width: 1599px) {
  html {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1439px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 650px) {
  html {
    font-size: 10px;
  }
}
