@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-inter;
        background-color: #02222d;
    }

    html {
        scroll-behavior: smooth;
        box-sizing: border-box;
    }
}

@layer components {
    .btn-solid {
        @apply py-1.5 px-6 text-white rounded-lg text-base transition-all duration-500 hover:opacity-90;
    }
}

.blue-purple-gradient {
    background: linear-gradient(90deg, #4776e6 0%, #0061a7 100%);
}

.bg-gradient {
    background: radial-gradient(
        55.88% 799.61% at 50% 50%,
        #4776e6 0%,
        rgba(0, 97, 167, 0) 100%
    );
}

.gradient-border-tb {
    /* padding: 1.5px 0;
    background: linear-gradient(
                90deg,
                transparent,
                #4776e6,
                #4776e6,
                transparent
            )
            top,
        linear-gradient(90deg, transparent, #4776e6, #4776e6, transparent)
            bottom;
    background-image: url('../images/whale.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */

    & > div {
        background: rgba(255, 255, 255, 0.03);
        box-shadow: 8px 9px 32px 0px rgba(0, 0, 0, 0.25);
        padding: 24px;
        border-radius: 8px;
    }
}

.gradient-border {
    padding: 1.5px 0;
    background: linear-gradient(
                90deg,
                transparent,
                #4776e6,
                #4776e6,
                transparent
            )
            top,
        linear-gradient(90deg, transparent, #4776e6, #4776e6, transparent)
            bottom;
    background-image: url('../images/whale.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.border-gradient {
    background-image: url('../images/buttonGradient.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.box-gradient {
    background-image: url('../images/boxGradient.png');
    background-size: cover;
    background-position: center;
}

.borber-gradient-2 {
    background-image: url('../images/border-gradient.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bg-whale {
    background-image: url('../images/whaleImg.png');
    background-size: auto 90%;
    background-repeat: no-repeat;
}

.borber-gradient {
    background-image: url('../images/bg-gradient.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bg-whale {
    background-image: url('../images/whaleImg.png');
    background-size: auto 90%;
    background-repeat: no-repeat;
    background-position: center;
}

.bg-liquidate {
    background-image: url('../images/liquidatePage.jpg');
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
}

.bg-dashboard {
    background-image: url('../images/dashboard.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-market {
    background-image: url('../images/bg-market.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
